<template>
  <div :class="b('', { isHighlighted: isToday || isHighlighted, variant })">
    <div :class="b('schedule-item', { isCompleted, isHighlighted: isToday || isHighlighted })">
      <component
        :is="showGameReportButton && variant !== 'slider' ? 'a' : 'div'"
        :class="b('link')"
        :href="gameDetailUrl"
      >
        <div :class="b('league-name')">{{ leagueName }}</div>
        <div :class="b('encounter')">
          <div :class="b('team', { home: true })">
            <span
              v-if="!hideTeamNames"
              :class="b('team-name')">
              {{ homeTeam.shortName }}
            </span>

            <c-team-logo
              :class="b('team-logo')"
              :team-id="homeTeam.id"
              :team-name="homeTeam.longName"
              show-flat
            />
          </div>

          <div :class="b('result')">
            <div :class="b('score')">
              <template v-if="isCompleted || isLive">{{ homeScore }}:{{ awayScore }}</template>
              <template v-else>VS</template>
            </div>
            <div :class="b('status')">
              {{ gameStatusLabel }}
            </div>
          </div>

          <div :class="b('team', { away: true })">
            <span
              v-if="!hideTeamNames"
              :class="b('team-name')">
              {{ awayTeam.shortName }}
            </span>

            <c-team-logo
              :class="b('team-logo')"
              :team-id="awayTeam.id"
              :team-name="awayTeam.longName"
              show-flat
            />
          </div>
        </div>

        <div :class="b('game-date')">
          <span :class="b('date')">{{ gameDate }}</span>
          <span :class="b('time')">{{ gameTime }}</span>
        </div>
      </component>
      <div :class="b('actions')">
        <v-btn
          v-if="showGameReportButton"
          :href="gameDetailUrl"
          :class="b('button')"
          :color="isCompleted || isToday ? 'blue-darken-3' : 'grey-lighten-2'"
        >
          {{ $viewport.isMobile ? 'Gamecenter' : 'Spielbericht' }}
        </v-btn>
        <v-btn
          v-if="streamingUrl"
          :href="streamingUrl"
          target="_blank"
          rel="noopener noreferrer"
          :class="b('button')"
          :color="isCompleted || isToday ? 'blue-darken-3' : 'grey-lighten-1'"
        >
          {{ $viewport.isMobile ? 'Stream' : 'Live-Stream' }}
        </v-btn>
      </div>
    </div>

    <div
      v-if="false && (isToday || isHighlighted)"
      :class="b('sponsor')"
    >
      <span :class="b('sponsor-label')"> Präsentiert von </span>
      <div :class="b('sponsor-logo-container')">
        <c-sponsor-logo />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import dayjs from 'dayjs';
  import { PropType } from 'vue';
  import { GAME } from '@/setup/routes-constants';
  import cSponsorLogo from '@/components/c-sponsor-logo.vue';
  import cTeamLogo from '@/components/c-team-logo.vue';
  import { TeamData } from '@/types/team';

  // type Setup = {};

  export default {
    name: 'c-schedule-item',
    components: {
      cSponsorLogo,
      cTeamLogo,
    },
    // mixins: [],

    props: {
      /**
       * The variant how the component should be displayed.
       */
      variant: {
        type: String,
        default: 'schedule',
        validator: (value: string) => [
          'schedule',
          'game-report',
          'slider'
        ].includes(value),
      },

      /**
       * TODO
       */
      gameId: {
        type: String,
        default: null,
      },

      /**
       * TODO
       */
      homeTeam: {
        type: Object as PropType<TeamData>,
        default: null,
      },

      /**
       * TODO
       */
      awayTeam: {
        type: Object as PropType<TeamData>,
        default: null,
      },

      /**
       * TODO
       */
      leagueName: {
        type: String,
        default: '',
      },

      /**
       * Todo
       */
      homeScore: {
        type: Number,
        default: 0,
      },

      /**
       * Todo
       */
      awayScore: {
        type: Number,
        default: 0,
      },

      /**
       * Todo
       */
      timestamp: {
        type: Number,
        default: null,
      },

      /**
       * Indicates if the game is in overtime
       */
      isOvertime: {
        type: [Boolean, Number],
        default: false,
      },

      /**
       * Indicates if the game is in a shoot out
       */
      isShootOut: {
        type: [Boolean, Number],
        default: false,
      },

      /**
       * TODO
       */
      isCompleted: {
        type: Boolean,
        default: false,
      },

      /**
       * TODO
       */
      isLive: {
        type: Boolean,
        default: false,
      },

      /**
       * TODO
       */
      isHighlighted: {
        type: Boolean,
        default: false,
      },

      /**
       * TODO
       */
      isToday: {
        type: Boolean,
        default: false,
      },

      /**
       * TODO
       */
      isDateTbd: {
        type: Boolean,
        default: false,
      },

      /**
       * TODO
       */
      isTimeTbd: {
        type: Boolean,
        default: false,
      },

      /**
       * TODO
       */
      streamingUrl: {
        type: String,
        default: null,
      },

      /**
       * TODO
       */
      showGameReportButton: {
        type: Boolean,
        default: true,
      },

      /**
       * TODO
       */
      hideTeamNames: {
        type: Boolean,
        default: false,
      },

      /**
       * TODO
       */
      isWordpress: {
        type: Boolean,
        default: false,
      },
    },

    // emits: [],

    // setup(): Setup {
    //   return {
    //   };
    // },
    // data() {
    //   return {
    //   };
    // },

    computed: {
      gameDetailUrl(): string {
        if (!this.isWordpress) {
          const detailUrl = this.$router.resolve({
            name: GAME.name,
            params: {
              gameId: this.gameId,
            },
          });

          return detailUrl?.href;
        }

        return `/gamecenter/#/gc/game/${this.gameId}`
      },

      gameStatusLabel(): string {
        if (!this.isCompleted) {
          return '';
        }

        return ['ENDE', this.isOvertime ? 'OT' : '', this.isShootOut ? 'SO' : ''].join(' ');
      },

      gameDate(): string {
        return this.isDateTbd || !this.timestamp ? 'Datum folgt' : dayjs(this.timestamp).format('dd | DD.MM.');
      },

      gameTime(): string {
        return this.isTimeTbd || !this.timestamp ? 'Uhrzeit folgt' : dayjs(this.timestamp).format('HH:mm');
      },
    },
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeDestroy() {},
    // destroyed() {},

    // methods: {},
    // render() {},
  };
</script>

<style lang="scss">
  @use '@/setup/scss/mixins';
  @use '@/setup/scss/variables';
  @use 'vuetify/settings' as vuetify;

  .c-schedule-item {
    $this: &;
    $font-size-factor: 0;

    --border-radius: 0.625rem;
    --team-logo-size: 3.5rem;

    margin-bottom: 0.5rem;

    @include mixins.media(sm) {
      margin-bottom: 2rem;
    }

    &--is-highlighted {
      margin-top: 1.25rem;
      margin-bottom: 1.25rem;
      border-width: 1px;
      border-color: variables.$color-blue-dark;

      @include mixins.media(sm) {
        margin-top: 5rem;
        margin-bottom: 5rem;
      }
    }

    &__schedule-item {
      position: relative;
      display: grid;
      width: 100%;
      padding: 0.65rem;
      border-radius: var(--border-radius);
      border: 1px solid rgba(variables.$color-grayscale--0, 0.01);
      background: variables.$color-grayscale--1000;
      box-shadow: 0 0 variables.$color-grayscale--0, 0 0 variables.$color-grayscale--0,
        0 3px 20px 0 rgba(variables.$color-grayscale--0, 0.16);

      @include mixins.media(sm) {
        padding: 1rem;
      }

      &--is-completed {
        background: map-get(vuetify.$grey, 'lighten-4');
        box-shadow: none;
      }

      &--is-highlighted {
        margin-top: 1.25rem;
        border-width: 1px;
        border-color: variables.$color-blue-dark;
      }
    }

    &__link {
      color: inherit;
      text-decoration: inherit;
    }

    &__league-name {
      text-align: center;
      font-size: variables.$font-size--xs;
      line-height: variables.$font-size--small;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      font-family: variables.$font-family-default;
      color: map-get(vuetify.$grey, 'base');
      margin-bottom: 3px;

      @include mixins.media(sm) {
        font-size: 0.75rem;
        line-height: 1rem;
        letter-spacing: 1.5px;
      }
    }

    &__encounter {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      text-align: center;
      margin-bottom: 3px;

      @include mixins.media(sm) {
        gap: 1.25rem;
      }
    }

    &__team {
      display: flex;
      flex: 1 1 0;
      align-items: center;
      justify-content: center;
      gap: 1.25rem;

      @include mixins.media(sm) {
        gap: 2.5rem;
        justify-content: flex-end;
      }

      &--away {
        flex-direction: row-reverse;
      }
    }

    &__team-name {
      font-size: variables.$font-size--h6;
      line-height: 1;
      text-transform: uppercase;
      font-family: variables.$font-family-upbolters;

      @include mixins.media($down: xs) {
        display: none;
      }

      @include mixins.media(sm) {
        font-size: variables.$font-size--h5;
        line-height: 1;
      }
    }

    &__team-logo {
      height: var(--team-logo-size);
      width: var(--team-logo-size);
      object-fit: contain;

      @include mixins.media(sm) {
        --team-logo-size: 5.35rem;
      }
    }

    &__result {
      position: relative;
      max-width: 12.125rem;
      flex: 1 1 0;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }

    &__score {
      font-family: variables.$font-family-upbolters;
      font-size: variables.$font-size--h4;
      line-height: 1;
      text-transform: uppercase;

      @include mixins.media(sm) {
        font-size: variables.$font-size--h1;
        line-height: 1;
      }
    }

    &__status {
      font-family: variables.$font-family-default;
      position: absolute;
      bottom: 0;
      margin-bottom: -0.75rem;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: variables.$font-size--smaller;

      @include mixins.media(sm) {
        font-size: variables.$font-size--default;
      }
    }

    &__game-date {
      text-align: center;
      line-height: 1.5rem;

      @include mixins.media(sm) {
        line-height: 2rem;
      }
    }

    &__date {
      font-weight: bold;
      text-transform: uppercase;
      margin-right: 0.4rem;
    }

    &__date,
    &__time {
      font-family: variables.$font-family-default-condensed;
      font-size: variables.$font-size--smaller;

      @include mixins.media(sm) {
        font-size: 1.1rem;
      }
    }

    &__actions {
      display: flex;
      flex-wrap: wrap;
      gap: 1.5rem;
      row-gap: 0.5rem;
      margin-top: 0.5rem;
      align-items: center;
      justify-content: center;

      @include mixins.media($down: xs) {
        display: none;
      }
    }

    &__button {
      font-family: variables.$font-family-default-condensed;
      font-weight: bold;
      text-transform: uppercase !important; // stylelint-disable-line
      letter-spacing: 0.5px;
      font-size: variables.$font-size--default;
    }

    &__sponsor {
      background: variables.$color-grayscale--1000;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1.25rem;
      width: 80%;
      margin: auto;
      box-shadow: 0 0 variables.$color-grayscale--0, 0 0 variables.$color-grayscale--0,
        0 3px 20px 0 rgba(variables.$color-grayscale--0, 0.16);
      padding: 0.25rem;
      border-radius: 0 0 0.625rem 0.625rem;
    }

    &__sponsor-label {
      width: 50%;
      text-align: right;
      font-size: 0.625rem;
      line-height: 1;
      text-transform: uppercase;
      letter-spacing: 0.1em;
    }

    &__sponsor-logo-container {
      position: relative;
      width: 50%;
      flex-grow: 1;
      text-align: left;

      img {
        width: 80px;
      }
    }

    &--variant-game-report {
      margin-bottom: 0;

      #{$this}__schedule-item {
        padding: 2rem 0.65rem;
        border-radius: var(--border-radius) var(--border-radius) 0 0;
      }

      #{$this}__team-logo {
        height: 4rem;
        width: 4rem;

        @include mixins.media(sm) {
          height: 5.8rem;
          width: 5.8rem;
        }
      }
    }

    &--variant-slider {
      margin: 10px 0;

      #{$this}__schedule-item {
        padding: 0.8rem;
        box-shadow: 0 0 variables.$color-grayscale--0, 0 0 variables.$color-grayscale--0,
        0 2px 8px 0 rgba(variables.$color-grayscale--0, 0.16);
      }

      #{$this}__score {
        font-size: variables.$font-size--h3;
      }

      #{$this}__team-logo {
        @include mixins.media(sm) {
          --team-logo-size: 4.3rem;
        }
      }

      #{$this}__actions {
        @include mixins.media($down: xs) {
          display: block;
        }
      }

      #{$this}__button {
        --v-btn-height: 26px;

        font-size: variables.$font-size--small;
        padding: 2px 8px;
      }
    }
  }
</style>
