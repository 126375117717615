<template>
  <div :class="b()"><template v-if="showHash">#</template>{{ jerseyNumber }}</div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';

  // type Setup = {}
  // type Data = {}

  /**
   *
   */
  export default defineComponent({
    name: 'e-player-number',

    // components: {},

    props: {
      /**
       * The number,
       */
      jerseyNumber: {
        type: Number,
        default: 0,
      },

      /**
       * The number,
       */
      showHash: {
        type: Boolean,
        default: true,
      },
    },
    // emits: [],

    // setup(): Setup {
    //   return {};
    // },
    // data(): Data {
    //   return {};
    // },

    // computed: {},
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    // methods: {},
    // render() {},
  });
</script>

<style lang="scss">
  @use 'sass:map';
  @use 'vuetify/settings' as vuetify;
  @use '@/setup/scss/mixins';
  @use '@/setup/scss/variables';

  .e-player-number {
    $this: &;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    border-radius: 50%;
    background: map.get(vuetify.$grey, 'lighten-2');
    font-family: variables.$font-family-babas;
    line-height: 1;
    aspect-ratio: 1;
  }
</style>
