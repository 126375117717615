<template>
  <div>
    <template v-if="teamsStore.runningRequests.apiFetchTeamDetails">
      <v-skeleton-loader type="image" />
      <v-row class="mt-3">
        <v-col cols="4">
          <v-skeleton-loader type="card" />
        </v-col>
        <v-col cols="4">
          <v-skeleton-loader type="card" />
        </v-col>
        <v-col cols="4">
          <v-skeleton-loader type="card" />
        </v-col>
      </v-row>
    </template>

    <div
      v-else-if="!apiError && isTeamDataReady"
      :class="b()"
    >
      <div
        v-if="!teamImage"
        :class="b('header')"
        :style="{ backgroundImage: `url(${teamLogo})` }"
      >
        <div :class="b('header-inner')">
          <c-team-logo
            :class="b('header-logo')"
            :team-id="team.teamData.id"
            show-flat
          />
          <div :class="b('header-title')">
            {{ team.teamData.teamShortname }}
          </div>
          <div :class="b('header-subtitle')">
            {{ team.teamData.teamLongname }}
          </div>
        </div>
      </div>
      <div
        v-else
        :class="b('header', { teamImage: true })"
      >
        <img
          :src="teamImage"
          :class="b('header-image')"
          alt="team image"
        />
      </div>

      <v-tabs
        v-model="activeTab"
        :class="b('tabs')"
        align-tabs="center"
      >
        <v-tab
          v-for="tab in tabs"
          :key="tab.title"
          :value="tab.title"
        >
          {{ tab.title }}
        </v-tab>
      </v-tabs>

      <v-tabs-window v-model="activeTab">
        <v-tabs-window-item value="Spieler">
          <div class="d-flex align-end flex-column pa-6">
            <v-tabs
              v-model="viewMode"
              :class="b('toggle')"
            >
              <v-tab value="list">
                <v-icon
                  icon="mdi-view-list"
                  class="mr-3"
                />
                Liste
              </v-tab>
              <v-tab value="cards">
                <v-icon
                  icon="mdi-card-account-details"
                  class="mr-3"
                />
                Details
              </v-tab>
            </v-tabs>
          </div>

          <v-tabs-window v-model="viewMode">
            <v-tabs-window-item value="list">
              <c-team-roster-table
                :field-players="team.players.fieldplayers"
                :goal-keepers="team.players.goalkeepers"
              />
            </v-tabs-window-item>

            <v-tabs-window-item value="cards">
              <c-team-roster-cards
                :fieldplayers="team.players.fieldplayers"
                :goalkeepers="team.players.goalkeepers"
              />
            </v-tabs-window-item>
          </v-tabs-window>
        </v-tabs-window-item>
        <v-tabs-window-item value="Statistik">
          <c-team-stats :team="team" />
        </v-tabs-window-item>
      </v-tabs-window>
    </div>

    <c-error-panel v-else />
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import config from '@/setup/js/config';
  import leagueConfig from '@/setup/js/leagues/config';
  import useSessionStore from '@/stores/session';
  import useTeamsStore from '@/stores/teams';
  import cErrorPanel from '@/components/c-error-panel.vue';
  import cTeamLogo from '@/components/c-team-logo.vue';
  import cTeamRosterCards from '@/components/c-team-roster-cards.vue';
  import cTeamRosterTable from '@/components/c-team-roster-table.vue';
  import cTeamStats from '@/components/c-team-stats.vue';
  import { TeamConfig, TeamSeasonConfig } from '@/types/leauge-config';
  import { TeamDetails } from '@/types/team-details';

  type Setup = {
    teamsStore: ReturnType<typeof useTeamsStore>;
    sessionStore: ReturnType<typeof useSessionStore>;
  };
  type Data = {
    viewMode: 'list' | 'cards';
    activeTab: string;
    tabs: Array<{ title: string }>;
    apiError: boolean;
  };

  /**
   *
   */
  export default defineComponent({
    name: 'l-team',
    components: {
      cErrorPanel,
      cTeamStats,
      cTeamRosterCards,
      cTeamRosterTable,
      cTeamLogo,
    },

    // components: {},

    props: {
      /**
       * A division ID.
       */
      divisionId: {
        type: String,
        default: '',
      },

      /**
       * A team ID.
       */
      teamId: {
        type: String,
        default: '',
      },

      /**
       * A team image.
       */
      teamImage: {
        type: String,
        default: null,
      },
    },
    // emits: [],

    setup(): Setup {
      return {
        teamsStore: useTeamsStore(),
        sessionStore: useSessionStore(),
      };
    },
    data(): Data {
      return {
        viewMode: 'cards',
        activeTab: 'Spieler',
        tabs: [{ title: 'Spieler' }, { title: 'Statistik' }],
        apiError: false,
      };
    },

    computed: {
      configParams(): { teamId: string; divisionId: string } {
        const teamId = (this.$route?.params?.teamId ? this.$route.params.teamId : this.teamId) as string;
        let divisionId = (this.$route?.params?.divisionId ? this.$route.params.divisionId : this.divisionId) as string;

        if (!divisionId) {
          const validTeam = leagueConfig.teams.find((team) =>
            team.seasons.find((season) => String(season.teamId) === String(teamId))
          );
          const validSeason = validTeam ? validTeam?.seasons[0] : null;

          if (validTeam && validSeason) {
            divisionId = String(validSeason.divisionId);

            this.setSessionData(validTeam, validSeason);
          } else {
            console.error('l-team: no season found for team: ', teamId);
          }
        }

        return { teamId, divisionId };
      },

      teamLogo(): string {
        return this.configParams.teamId ? `${config.LOGO_BASE_URL_API}/${this.configParams.teamId}.png` : '';
      },

      team(): TeamDetails {
        return (
          this.teamsStore.teamDetails?.[this.configParams.divisionId]?.[this.configParams.teamId] || ({} as TeamDetails)
        );
      },

      isTeamDataReady(): boolean {
        return !!this.team?.divisionId;
      },
    },
    watch: {
      configParams: {
        handler(newVal) {
          if (!newVal.teamId || !newVal.divisionId) {
            this.apiError = true;

            return;
          }

          this.teamsStore
            .apiFetchTeamDetails(newVal.teamId, newVal.divisionId)
            .then(() => {
              this.apiError = false;
            })
            .catch(() => {
              this.apiError = true;
            });
        },
        immediate: true,
      },
    },

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    methods: {
      setSessionData(activeTeam: TeamConfig, activeSeason: TeamSeasonConfig) {
        this.sessionStore.activeTeam = activeTeam;
        this.sessionStore.activeSeason = activeSeason;
      },
    },
    // render() {},
  });
</script>

<style lang="scss">
  @use 'sass:map';
  @use 'vuetify/settings' as vuetify;
  @use '@/setup/scss/mixins';
  @use '@/setup/scss/variables';

  .l-team {
    $this: &;

    // TODO: This is a fix for: https://github.com/vuetifyjs/vuetify/issues/18447
    .v-window__container {
      min-height: 500px;
    }

    &__header {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;

      &--team-image {
        display: flex;
        width: 100%;
        overflow: hidden;
      }
    }

    &__header-inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      backdrop-filter: blur(20px) grayscale(80%);
      background-color: rgba(map-get(vuetify.$grey, 'lighten-2'), 0.7);
      padding: 25px;
    }

    &__header-logo {
      margin-bottom: 10px;
      width: 180px;
      aspect-ratio: 1;

      .c-team-logo {
        width: 100%;
      }
    }

    &__header-image {
      width: 100%;
    }

    &__header-title {
      font-size: variables.$font-size--h1;
      font-family: variables.$font-family-babas;
    }

    &__header-subtitle {
      font-size: variables.$font-size--h3;
      font-family: variables.$font-family-babas;
    }

    &__tabs {
      display: flex;
      align-items: center;
      background-color: rgba(map-get(vuetify.$grey, 'lighten-2'), 0.7);
    }
  }
</style>
