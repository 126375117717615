<template>
  <div :class="b()">
    <carousel
      v-model="currentSlide"
      :items-to-show="$viewport.isMobile ? 1.3 : 2"
      :autoplay="6000"
      :transition="1000"
      pause-autoplay-on-hover
      wrap-around
    >
      <slide v-for="(game, index) in getSchedule" :key="index">
        <e-schedule-item
          :class="b('game')"
          variant="slider"
          :game-id="game.id"
          :home-team="game.hdwpCustomData.teams.home"
          :away-team="game.hdwpCustomData.teams.away"
          :league-name="game.leagueName"
          :home-score="game.homeTeamScore"
          :away-score="game.awayTeamScore"
          :timestamp="game.gameUtcTimestamp"
          :is-date-tbd="game.dateIsToBeDetermined"
          :is-time-tbd="game.timeIsToBeDetermined"
          :is-shoot-out="game.isShootOut"
          :is-overtime="game.isOvertime"
          :is-completed="game.hdwpCustomData.isComplete"
          :is-live="game.hdwpCustomData.isLive"
          :is-today="game.hdwpCustomData.isToday"
          :streaming-url="game.hdwpCustomData.streams?.[0]?.targetUrl"
          hide-team-names
          is-wordpress
        />
      </slide>

      <template #addons>
        <pagination />
      </template>
    </carousel>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import 'vue3-carousel/dist/carousel.css';
  import { Carousel, Pagination, Slide } from 'vue3-carousel';
  import useScheduleStore from '@/stores/schedule';
  import { ScheduleRaw } from '@/types/schedule-response';
  import { TeamConfig } from '@/types/leauge-config';
  import { seasonConfig as OEL } from '@/setup/js/leagues/oeel';
  import { seasonConfig as DEBL2 } from '@/setup/js/leagues/debl2';
  import { seasonConfig as VEHL1 } from '@/setup/js/leagues/vehl1';
  import { seasonConfig as VEHL2 } from '@/setup/js/leagues/vehl3';
  import { seasonConfig as VEHL3 } from '@/setup/js/leagues/vehl4';
  import eScheduleItem from '@/components/c-schedule-item.vue';

  type Setup = {
    scheduleStore: ReturnType<typeof useScheduleStore>;
  }
  type Data = {
    currentSlide: number;
    teamConfigs: TeamConfig[];
  }

  /**
   *
   */
  export default defineComponent({
    name: 'c-schedule-slider',

    components: {
      eScheduleItem,
      carousel: Carousel,
      slide: Slide,
      pagination: Pagination,
    },

    // props: {},
    // emits: [],

    setup(): Setup {
      return {
        scheduleStore: useScheduleStore(),
      };
    },
    data(): Data {
      return {
        currentSlide: 0,
        teamConfigs: [OEL, DEBL2, VEHL1, VEHL2, VEHL3]
      };
    },

    computed: {
      getSchedule(): ScheduleRaw[] {
        const items = [] as ScheduleRaw[];

        this.teamConfigs.forEach(config => {
          const divisionId = config.seasons[0]?.divisionId || '';
          const teamId = config.seasons[0]?.teamId || '';
          const schedule = this.scheduleStore.getScheduleForTeamId(divisionId, teamId);
          const gameToDisplay = schedule.find(item => item.hdwpCustomData.isLive || item.hdwpCustomData.isFuture);

          if (gameToDisplay) {
            items.push({
              ...gameToDisplay,
              leagueName: config.name
            });
          }
        });

        return items;
      }
    },
    watch: {
      getSchedule() {
        if (this.getSchedule.length) {
          this.currentSlide = 0;
        }
      }
    },

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    mounted() {
      this.teamConfigs.forEach(config => this.scheduleStore.apiFetchSchedule(Number(config.seasons[0]?.divisionId)));
    },
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    // methods: {},
    // render() {},
  });
</script>

<style lang="scss">
@use 'sass:map';
@use 'vuetify/settings' as vuetify;
@use '@/setup/scss/mixins';
@use '@/setup/scss/variables';

.c-schedule-slider {
  $this: &;

  &__game {
    width: 95%;
  }

  .carousel__slide { // stylelint-disable-line
    opacity: 0.3;
  }

  .carousel__slide--active { // stylelint-disable-line
    opacity: 1;
  }
}
</style>
