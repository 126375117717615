import dayjs from 'dayjs';
import { defineStore } from 'pinia';
import { Store } from '@/setup/globals';
import { getApiUrlWithParams } from '@/helpers/get-api-url';
import { ScheduleRaw, StreamAnyObject } from '@/types/schedule-response';

type Schedule = {
  [key: string]: ScheduleRaw[];
};

type ScheduleState = {
  /**
   * Stores the schedule.
   */
  schedule: Schedule;

  /**
   * Holds the info for running api calls.
   */
  runningRequests: {
    apiFetchSchedule: boolean;
  };
};

const storeName = Store.Schedule;

function extendGameData(game: ScheduleRaw): ScheduleRaw {
  const gameDate = game?.gameUtcTimestamp ? dayjs(game.gameUtcTimestamp) : dayjs();
  const now = dayjs();
  const isToday = now.isSame(gameDate, 'day');
  const isAfterNow = now.isSameOrAfter(gameDate);

  if (!game?.gameUtcTimestamp) {
    console.error('extendGameData: gameUtcTimestamp is missing for game', game.id);
  }

  const streams = [] as StreamAnyObject[];

  if (game.streams.length) {
    game.streams.forEach((stream: string) => streams.push(JSON.parse(stream)));
  }

  return {
    ...game,
    hdwpCustomData: {
      isToday,
      isFuture: game.gameStatus === 0 || gameDate.isSame(now) || gameDate.isAfter(now),
      isComplete: game.gameStatus >= 4 || game.gameHasEnded || now.isAfter(gameDate.add(4, 'hours')),
      isLive: isToday && (isAfterNow || game.gameStatus > 0),
      isHighlighted: false,
      streams,
      teams: {
        home: {
          id: game.homeTeamId,
          longName: game.homeTeamLongName,
          shortName: game.homeTeamShortName,
        },
        away: {
          id: game.awayTeamId,
          longName: game.awayTeamLongName,
          shortName: game.awayTeamShortName,
        }
      }
    },
  };
}

export default defineStore(storeName, {
  state: () => {
    const state: ScheduleState = {
      schedule: {},
      runningRequests: {
        apiFetchSchedule: false,
      },
    };

    return state;
  },
  getters: {
    getScheduleForDivision() {
      return (divisionId: string | number): ScheduleRaw[] => this.schedule?.[String(divisionId)] || [];
    },

    getScheduleForTeamId() {
      return (
        divisionId: string | number,
        teamId: string | number,
        teamIdList?: string[] | number[]
      ): ScheduleRaw[] => {
        const games = this.schedule[divisionId];

        if (!games || !games.length) {
          return [];
        }

        if (teamIdList && teamIdList.length) {
          // TODO: fix typescript
          // @ts-ignore next-line
          return games.filter((game) => teamIdList.includes(game.homeTeamId) || teamIdList.includes(game.awayTeamId));
        }

        return games.filter((game) => game.homeTeamId === teamId || game.awayTeamId === teamId);
      };
    },
  },
  actions: {
    async apiFetchSchedule(divisionId: number): Promise<void> {
      this.runningRequests.apiFetchSchedule = true;

      const url = getApiUrlWithParams('getSchedule', { divisionId });

      return this.$api
        .getJsonP(url)
        .then((response) => {
          const { data } = response || {};

          if (data?.rows?.length) {
            const games = data?.rows || [];

            // Games[0].gameUtcTimestamp = dayjs().unix() * 1000;

            this.schedule[divisionId] = games.map((game: ScheduleRaw) => extendGameData(game));
          }
        })
        .finally(() => {
          this.runningRequests.apiFetchSchedule = false;
        });
    },
  },
});
